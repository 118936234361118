body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root{
  max-width: 100%;
  overflow-x: hidden;
}

h3 {
  color:#001f3f;
}

.light {
  opacity: 0.8;
}

p {
  font-size: 16px !important;
}

nav {
  position: fixed !important;
  width: 100%;
  z-index: 10;
  top: 0;
}

a {
  cursor: pointer;
}

.modal-content {
  padding: 10px;
}

.product-item {
  padding: 7px;
  font-size: 15px;
  color: #000;
  width: 80%;
  background-color: #ebebeb;
  opacity: 0.8;
  box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.75);
}

.product-link {
  color: #001f3f !important;
}

.legals {
  font-size: 13px;
}

.data-protection {
  text-decoration: underline !important;
  color: #000 !important;
}

.btn-primary {
  background-color: rgba(0, 152, 80) !important;
  border: unset !important;
  -webkit-transition: 0.3s all;
  transition: 0.3s all;
}

.btn-primary:hover {
  -webkit-transition: 0.3s all;
  transition: 0.3s all;
  background-color: rgba(0, 152, 81, 0.856) !important;
}

.page-title {
  margin-top: 12%;
  text-shadow: 0 0 3px #fff;
}

.page-subtitle {
  text-shadow: 0 0 3px #fff;
}

.row {
  margin-bottom: 20px;
}

.card {
  margin-bottom: 10px;
  border-bottom: 6px solid rgba(0, 152, 80) !important;
}


.jumbotron {
  /* background-image: url("https://images.unsplash.com/photo-1523841589119-b55aee0f66e7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1650&q=80"); */
  background-size: cover;
  padding: auto !important;
}

.call-now {
  width: 100%;
  background-color: rgba(0, 152, 80);
  height: 100%;
  color: #fff !important; 
  opacity: 0.9;
  padding: 25px;
  border-bottom: 5px solid #ebebeb;
}

.call-now h3 {
  margin-top: 10px;
  color: #fff;
}

.footer {
  width: 100%;
  height: auto;
  padding: 10px;
  background-color:rgba(0, 152, 80);
  color: #fff;
}

.blinking{
  -webkit-animation:blinkingText 1.2s infinite;
          animation:blinkingText 1.2s infinite;
}
@-webkit-keyframes blinkingText{
  0%{     color: #000;    }
  49%{    color: #000; }
  60%{    color: transparent; }
  99%{    color:transparent;  }
  100%{   color: #000;    }
}
@keyframes blinkingText{
  0%{     color: #000;    }
  49%{    color: #000; }
  60%{    color: transparent; }
  99%{    color:transparent;  }
  100%{   color: #000;    }
}
